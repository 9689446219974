<template>
  <div v-loading="loading" class="order-detail-container">
    <div class="buttonbc">
      <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
        <img src="@/assets/back.png" alt />
      </el-button>
    </div>
    <div class="cont" v-if="couponOrderDetail">
      <br />
      <h4>订单信息</h4>
      <table class="table-cont">
        <tbody>
          <tr>
            <td style="font-weight:700">订单信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td v-if="type === '1'">销售单据编号：{{ couponOrderDetail.sell_trade_sn }}</td>
            <td v-else>销售退单编号：{{ couponOrderDetail.sell_refund_record_sn }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>单据状态：{{ getTradeTypeName(couponOrderDetail.trade_status) }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>订单创建时间：{{ couponOrderDetail.create_time | unixToDate }}</td>
          </tr>
          <tr>
            <td style="font-weight:700">客户信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>客户名称：{{ couponOrderDetail.client_name }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>联系人名称：{{ couponOrderDetail.link_name }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>联系电话：{{ couponOrderDetail.link_phone }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>客户地址：{{ couponOrderDetail.client_addr }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>销售人员名称：{{ couponOrderDetail.sell_name }}</td>
          </tr>
          <tr>
            <td style="font-weight:700">卡券信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>卡券名称：{{ couponOrderDetail.card_name }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>应用类型：{{ getAppTypeName(couponOrderDetail.app_type_shop_type_flag) }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>卡券面值/价位：{{ couponOrderDetail.card_value }}</td>
          </tr>
          <tr class="detail-tr1">
            <td v-if="type === '1'">开卡数量：{{ couponOrderDetail.card_num }}</td>
            <td v-else>禁卡数量：{{ couponOrderDetail.card_num }}</td>
          </tr>
          <tr>
            <!-- <td style="font-weight:700">订单来源信息{{type}}</td> -->
            <td style="font-weight:700">订单来源信息</td>
          </tr>
          <tr class="detail-tr1">
            <td>来源应用：{{ couponOrderDetail.shopping || '现金商城' }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>商城名称：{{ couponOrderDetail.shop_name }}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <h4>卡券信息</h4>
      <template>
        <el-table :data="productList" :header-cell-style="{ background: '#cccccc' }">
          <el-table-column prop="card_name" label="卡券名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="card_value" label="面值"></el-table-column>
          <el-table-column prop="card_number_segments" label="卡号段">
            <template slot-scope="{row}">
              <div v-for="(item, index) of row.card_number_segments" :key="index" style="margin:5px 0;">
                <el-input style="width: 100px;" v-model="item.card_code_start" size="mini" disabled />&nbsp;——
                <el-input style="width: 100px;" v-model="item.card_code_end" size="mini" disabled />
                <el-button style="margin-left:5px;" type="primary" size="mini"
                  @click="selectActiveIndex(index)">查看</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <!--卡号信息-->
    <x-dialog :proxy="cardInfoDialog">
      <el-table :data="cardList" max-height="400px" :header-cell-style="{ background: '#cccccc' }">
        <el-table-column prop="card_name" label="编号">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="card_code" label="卡号"></el-table-column>
      </el-table>
      <div style="text-align:center;margin:10px;">
        <el-pagination @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
          :current-page="couponParams.page_no" :page-size="couponParams.page_size" :page-sizes="MixinPageSizes"
          :layout="MixinTableLayout" background :total="couponParams.data_total"></el-pagination>
      </div>
    </x-dialog>
  </div>
</template>

<script>
import * as API_refund from "@/api/refund";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import XTable from "@/components/x-table/x-table.vue";
import {
  $xTable,
  XTableProxy,
  XTableStdRequestParams,
  XTableStdResponse
} from "@/components/x-table/table.proxy";
import {
  getAppTypeName,
  getTradeTypeName
} from "@/views/tools/sale-card-open/utils/find";

export default {
  name: "couponOrderDetail",
  components: {
    XDialog,
    XTable
  },
  data () {
    return {
      type: "1",
      /** 列表loading状态 */
      loading: false,
      /** 订单详情数据 */
      couponOrderDetail: {},
      productList: [],
      cardList: [],
      // 卡号信息弹窗
      cardInfoDialog: $xDialog.create({
        title: "卡号信息"
      }),
      // 卡号查询条件
      couponParams: {
        start_number: "",
        end_number: "",
        card_id: 0,
        page_no: 1,
        page_size: 20
      },
      activeIndex: 0
    };
  },
  mounted () {
    this.id = this.$route.params.id;
    this.type = this.$route.query.type;
    sessionStorage.setItem('activeName', this.type)
    this.GET_CouponOrderDetail();
  },
  methods: {
    getTradeTypeName (value) {
      return getTradeTypeName(value)
    },
    getAppTypeName (value) {
      return getAppTypeName(value)
    },
    selectActiveIndex (index) {
      this.activeIndex = index;
      this.GET_QueryCardNumber();
    },
    GET_QueryCardNumber () {
      this.couponParams.start_number = this.productList[0].card_number_segments[
        this.activeIndex
      ].card_code_start;
      this.couponParams.end_number = this.productList[0].card_number_segments[
        this.activeIndex
      ].card_code_end;
      API_refund.getQueryCardNumber(this.couponParams).then(res => {
        this.cardList = res.data;
        this.couponParams.data_total = res.data_total;
        this.cardInfoDialog.display();
      });
    },
    /** 获取订单详情信息 */
    GET_CouponOrderDetail () {
      this.loading = true;
      if (this.type === "1") {
        API_refund.getCouponOrderDetail({ order_sell_id: this.id }).then(
          res => {
            this.loading = false;
            this.couponOrderDetail = res;
            this.couponParams.card_id = res.card_id;
            this.productList.push(this.couponOrderDetail);
          }
        );
      } else {
        API_refund.getRefundCouponOrderDetail({ refund_sell_id: this.id }).then(
          res => {
            this.loading = false;
            this.couponOrderDetail = res;
            this.couponParams.card_id = res.card_id;
            this.productList.push(this.couponOrderDetail);
          }
        );
      }
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.couponParams.page_size = size;
      this.GET_QueryCardNumber();
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.couponParams.page_no = page;
      this.GET_QueryCardNumber();
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-input.is-disabled .el-input__inner {
  background-color: #fff;
  color: #606266;
}

/deep/ .el-form-item {
  margin-bottom: 0;

  .el-form-item__label {
    line-height: 25px;
  }

  .el-form-item__content {
    line-height: 25px;
  }

  .el-radio-group {
    padding-top: 6px;
  }
}

.logistics-info {
  background-color: #fff;
  padding: 10px 10px 50px 10px;

  // text-align: center;
  .ship-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &:first-child {
      .ship-dot {
        background-color: #ec3a33;
      }

      p {
        color: #ec3a33;
      }
    }

    &:not(:last-child)::after {
      content: " ";
      position: absolute;
      z-index: 1;
      pointer-events: none;
      background-color: #e5e5e5;
      width: 1px;
      top: 37px;
      left: 10px;
      bottom: -20px;
    }
  }

  .ship-dot {
    position: relative;
    line-height: 20px;
    font-size: 10px;
    text-align: center;
    color: #666;
    margin: 24px 15px 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ccc;
  }

  .ship-msg {
    flex: 1;
    padding: 15px 0;
  }

  .ship-msg-text {
    color: #333;
    font-size: 14px;
    line-height: 18px;
  }

  .ship-msg-time {
    font-size: 12px;
    color: #999;
  }
}

.table-cont {
  margin-bottom: 0;
  font-size: 13px;
  width: 100%;
  max-width: 100%;

  .detail-tr1 {
    font-size: 12px;
    color: #a6a6a6;
  }
}

.table-cont.order-table td {
  border-top: 0;
}

.table-cont>tbody>tr>td {
  line-height: 1.42857;
  padding: 4px 8px;
  vertical-align: middle;
}

.m-l-md {
  margin-left: 20px !important;
}

.order-detail-container {
  background: #fff;
  position: relative;
  padding: 20px;
  border-radius: 5px;
}

.buttonbc {
  float: right;
  height: 40px;

  .back_btn {
    width: 36px;
    height: 36px;
    background-color: #1a43a9;
    border-radius: 50%;

    img {
      width: 10px;
      height: 20px;
      position: relative;
      top: -2px;
      left: -4px;
    }
  }
}

.cont {
  /* width: 1400px; */
  /* margin-left: 80px; */
  margin-left: 30px;
  padding-bottom: 20px;
}

/deep/ .is-leaf {
  /* border: 1px solid #000000 !important; */
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
}

/deep/ .el-table__row td {
  /* border: 1px solid #000000 !important; */
  text-align: center;
  font-size: 16px;
}

// /deep/ .el-table--border {
//   /* border: 1px solid #000000 !important; */
// }
.zongji {
  width: 100%;
  font-size: 26px;
  text-align: right;
  padding-top: 30px;
}

.sendhuo {
  margin-top: 60px;
  float: right;
}

.goods-image {
  width: 50px;
  height: 50px;
}
</style>
